<template>
    <div class="page-header header-filter"> <!-- filter-color="orange"-->
        <div class="page-header-image"
             style="background-image: url('/img/bg22.jpg')">
        </div>
        <div class="content">
            <div class="container">
                <div class="col-md-5 ml-auto mr-auto" style="background-color: rgba(255, 255, 255, 0.15);">
                    <card type="login" plain>
                        <div slot="header" class="logo-container" style="margin-top: 30px;">
                            <img v-lazy="'/svg/logo/autolearnify-logo-white.svg'">
                        </div>

                        <fg-input class="no-border input-lg"
                                  addon-left-icon="now-ui-icons users_circle-08"
                                  placeholder="Email..."
                                  v-model="email">
                        </fg-input>

                        <fg-input class="no-border input-lg"
                                  addon-left-icon="now-ui-icons text_caps-small"
                                  placeholder="Password..."
                                  type="password"
                        >
                        </fg-input>

                        <template slot="raw-content">
                            <div class="card-footer text-center">
                                <a rel="nofollow" :href="loginLink" class="btn btn-primary btn-round btn-lg btn-block">Sign in</a>
                            </div>
                            <div class="pull-left">
                                <h6>
                                    <a href="/signup" class="link footer-link">Create Account (It's Free)</a>
                                </h6>
                            </div>
                            <div class="pull-right">
                                <!--<h6>
                                    <a href="/help" class="link footer-link">Need Help?</a>
                                </h6>-->
                            </div>
                        </template>
                    </card>
                </div>
            </div>
        </div>
        <main-footer></main-footer>
    </div>
</template>
<script>
import { Card, Button, FormGroupInput } from "@/components";
import MainFooter from "@/layout/MainFooter";
export default {
  name: "login-page",
  bodyClass: "login-page",
  components: {
    Card,
    MainFooter,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      email: ""
    };
  },
  computed: {
    loginLink() {
      return "#https://app.autolearnify.com/login?email=" + this.email;
    }
  }
};
</script>
<style>
</style>
